<!--
 * @Auth: linjituan
 * @Date: 2021-08-26 11:48:06
 * @LastEditors: linjituan
 * @LastEditTime: 2021-09-18 10:44:45
-->
<template>
  <page-header-wrapper>
    <approval-apply ref="approvalApply" />
    <a-card>
      <div class="menu-page">
        <div class="menu-item" v-for="(item, index) in menuList" :key="index" @click="$refs.approvalApply.handleApply(item, 'add')" >
          <div class="content">
            <i :class="[item.icon ? 'iconfont ' + item.icon : '']" :style="'color:' + item.color + ';font-size:' + item.size"></i>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import approvalApply from '../approvalApply'
import { openModules } from '../approvalApply/menuConfig'
import { listApprovalForm } from '@/api/iot/approvalForm'
const vm = this
export default {
  components: { approvalApply },
  data() {
    return {
      openList: [],
      openListValue: [],
      menuList: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      console.log('getList-1')
      this.openList = openModules(vm)[0]
      this.openListValue = this.openList.modules
      const menuList = []
      listApprovalForm({}).then((response) => {
        const list = response.rows
        list.forEach((p) => {
          const tmpArr = this.openListValue.filter((t) => t.template === p.template)
          if (tmpArr.length > 0) {
            menuList.push({
              template: p.template,
              name: p.approveName,
              approvalKind: p.procdefKey,
              icon: p.icon,
              color: p.iconColor,
              size: tmpArr[0].size,
              component: tmpArr[0].component
            })
          }
        })
        this.menuList = menuList
      })
    }
  }
}
</script>

<style scoped lang="less">
.menu-page {
  overflow: hidden;
  .menu-item {
    padding: 15px 10px;
    width: 200px;
    cursor: pointer;
    border: 1px solid #d9dada;
    border-radius: 5px;
    float: left;
    margin: 5px 10px;
    &:hover {
      border: 1px solid #448ed7;
    }
    .content {
      height: 35px;
      line-height: 35px;
      overflow: hidden;
      display: flex;
      span {
        margin-left: 10px;
        color: #303133;
      }
    }
  }
}
</style>
