var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        [
          _c(_vm.currentComponent.component, {
            ref: _vm.currentComponent.refName,
            tag: "component",
            attrs: {
              refName: _vm.currentComponent.refName,
              approvalRecord: _vm.approvalRecord,
              isShow: _vm.isShow,
              action: _vm.action,
            },
            on: { componentClose: _vm.handleClose, ok: _vm.handleOk },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }