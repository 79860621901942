import request from '@/utils/request'

// 查询表单管理列表
export function listApprovalForm (query) {
  return request({
    url: '/iot/approvalForm/list',
    method: 'get',
    params: query
  })
}

// 查询表单管理详细
export function getApprovalForm (id) {
  return request({
    url: '/iot/approvalForm/' + id,
    method: 'get'
  })
}

// 新增表单管理
export function addApprovalForm (data) {
  return request({
    url: '/iot/approvalForm',
    method: 'post',
    data: data
  })
}

// 修改表单管理
export function updateApprovalForm (data) {
  return request({
    url: '/iot/approvalForm',
    method: 'put',
    data: data
  })
}

// 删除表单管理
export function delApprovalForm (id) {
  return request({
    url: '/iot/approvalForm/' + id,
    method: 'delete'
  })
}

// 导出表单管理
export function exportApprovalForm (query) {
  return request({
    url: '/iot/approvalForm/export',
    method: 'get',
    params: query
  })
}
