var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c("approval-apply", { ref: "approvalApply" }),
      _c("a-card", [
        _c(
          "div",
          { staticClass: "menu-page" },
          _vm._l(_vm.menuList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "menu-item",
                on: {
                  click: function ($event) {
                    return _vm.$refs.approvalApply.handleApply(item, "add")
                  },
                },
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c("i", {
                    class: [item.icon ? "iconfont " + item.icon : ""],
                    style: "color:" + item.color + ";font-size:" + item.size,
                  }),
                  _c("span", [_vm._v(_vm._s(item.name))]),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }