/*
 * @Auth: linjituan
 * @Date: 2021-12-20 17:08:16
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-21 17:08:23
 */
// import { listApprovalForm } from '@/api/iot/approvalForm'
export default {
  data() {
    return {}
  },

  methods: {
    async getTemplateId(approvalRecord) {
      if (approvalRecord.template) {
        return approvalRecord.template
      }
      if (approvalRecord.approvalKind.endsWith('expenses')) {
        return 1
      } else if (approvalRecord.approvalKind.endsWith('recharge')) {
        return 2
      } else if (approvalRecord.approvalKind.endsWith('contract')) {
        return 3
      } else {
        this.$warningEx('查找不到相应表单配置！')
      }

      // let currentItem = {}
      // await listApprovalForm({}).then((response) => {
      //   const list = response.rows
      //   const item = list.find((p) => p.procdefKey === approvalRecord.approvalKind)
      //   if (item) {
      //     currentItem = item.template
      //   } else {
      //     this.$warningEx('查找不到相应表单配置！')
      //   }
      // })
      // return currentItem
    },
    doCheckBeforeSave() {
      console.log('doCheckBeforeSave', this.haveUpload)

      // 上传文件名校验
      let billImage = this.form.billImage ? this.form.billImage.split(',') : []
      billImage = Array.from(new Set(billImage))
      if (!this.form.billImageUrl) {
        this.form.billImageUrl = []
      }
      if (billImage.length !== this.form.billImageUrl.length) {
        this.$warningEx('附件上传失败->文件丢失！')
        return false
      }
      for (let k = 0, n = billImage.length; k < n; k++) {
        if (!billImage[k] || !billImage[k].trim()) {
          this.$warningEx('附件上传失败->文件名为空！')
          return false
        }
      }
      if (this.form.billImageUrl && this.form.billImageName) {
        if (this.form.billImageUrl.length !== this.form.billImageName.length) {
          this.$warningEx('附件上传失败->文件丢失')
          return false
        }
      }
      if (this.form.billImageNum > 0 && this.form.billImageNum !== billImage.length) {
        this.$warningEx('附件上传失败->原始上传文件数量与实际上传文件数量不一致！')
        return false
      }
      delete this.form.initFileList
      return true
    }
  }
}
