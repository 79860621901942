<template>
  <div v-if="isShow">
    <component
      :is="currentComponent.component"
      :ref="currentComponent.refName"
      :refName="currentComponent.refName"
      :approvalRecord="approvalRecord"
      @componentClose="handleClose"
      :isShow="isShow"
      :action="action"
      @ok="handleOk"
    ></component>
  </div>
</template>
<script>
import { openModules } from './menuConfig'
import base from '../base'
const vm = this
export default {
  name: 'ApprovalApply',
  mixins: [base],
  data() {
    return {
      openList: [],
      openListValue: [],
      title: '',
      currentComponent: {},
      approvalRecord: null,
      isShow: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleClose() {
      console.log('handleClose')
      this.isShow = false
    },
    handleOk() {
      console.log('handleOk')
      this.isShow = false
      this.$emit('ok')
    },
    // 查看业务申请详情, 第二个参数是动作，如果action=add代表是新增,update代表是修改
    // 查看详情共性包含撤回与审批节点
    // 不同的详情要调用不同的业务申请接口
    async handleApply(approvalRecord, action) {
      console.log('handleUpdate')
      this.action = action
      this.approvalRecord = approvalRecord
      const templateId = await this.getTemplateId(approvalRecord)
      const tmpArr = this.openListValue.filter((p) => p.template === templateId)
      if (tmpArr.length === 1) {
        console.log('找到组件配置', approvalRecord.approvalKind)
        this.currentComponent = tmpArr[0]
        this.isShow = true
      } else {
        console.log('找不到组件配置', approvalRecord.approvalKind)
        this.currentComponent = {}
      }
    },
    getList() {
      this.openList = openModules(vm)[0]
      this.openListValue = this.openList.modules
    }
  }
}
</script>
